::-webkit-scrollbar {
	scrollbar-gutter: stable;
	background-color: rgba(0, 0, 0, 0);
	width: 6px;
	height: 6px;
	z-index: 999999;
}

::-webkit-scrollbar-track {
	background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0);
	border-radius: 16px;
	border: 0px solid #f2f2f2;
}

::-webkit-scrollbar-button {
	display: none;
}

::-webkit-scrollbar-corner {
	background-color: inherit;
}

:hover::-webkit-scrollbar-thumb {
	background-color: #808080;
	border: 1px solid #d3d3d3;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #808080;
	border: 1px solid #d3d3d3;
}

.adjust-recharts-wrapper .recharts-wrapper {
	height: 80% !important;
}
